@font-face {
    font-family: "Rational Display Medium";
    src: url("/fonts/RationalDisplayMedium.otf") format("opentype");
}

#login {
    font-family: "Rational Display Medium";
    background-color: #f0f4f7;
    background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%237aabb8' fill-opacity='0.18' fill-rule='evenodd'/%3E%3C/svg%3E");

    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
}

#login .loginCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10%;

    height: 35%;
    width: 50%;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 8px;
}

#login button,
#login input {
    font-family: "Rational Display Medium";
    height: 30px;
    width: 50%;
    margin-top: 10px;
}
#login button {
    margin: 0;
    margin-top: 10px;
    width: 51%;
}

#login .alert {
    color: #721c24;
    background-color: #f8d7da;
    border: solid #f5c6cb;
    border-radius: 5px;
    padding-top: 7px;
    padding-left: 10%;
    padding-right: 10%;
    height: 30px;
}

@media only screen and (max-width: 600px) {
    #login .loginCard {
        height: 35%;
        width: 75%;
    }
    #login button,
    #login input {
        width: 80%;
    }
    #login button {
        width: 81%;
    }
}

@media only screen and (max-height: 500px) {
    #login .loginCard {
        height: 75%;
        width: 55%;
        margin-top: 2%;
    }
    #login button,
    #login input {
        width: 80%;
    }
    #login button {
        width: 81%;
    }
}
